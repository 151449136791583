.login {
  --person-line1-text-color: white;
  --person-line2-text-color: white;
  --login-signed-in-hover-background: transparent;
  --login-flyout-command-text-color: black;
  --login-flyout-background-color: white;
  --login-add-account-button-text-color: white;
  --login-add-account-button-background-color: white;
  --login-add-account-button-hover-background-color: white;

}
