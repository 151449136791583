/* Spin Animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* Width and Height */
.h-6 {
  height: 1.5rem; /* 6 * 0.25rem = 1.5rem */
}

.w-6 {
  width: 1.5rem; /* 6 * 0.25rem = 1.5rem */
}